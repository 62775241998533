import React from 'react';
import Icon from '../Icon';
import classNames from 'classnames';
import styles from './AnnualRing.folkhem.module.scss';

class AnnualRing extends React.PureComponent {
    state = {
        rotateDegrees: Math.floor(Math.random() * Math.floor(360)),
        modifier: Math.floor(Math.random() * Math.floor(4)) + 1,
    };

    render() {
        const { rotateDegrees, modifier } = this.state;

        const classes = classNames(styles['AnnualRing'], {
            [styles[`AnnualRing--${modifier}`]]: modifier,
        });

        return (
            <div className={classes}>
                <Icon name="AnnualRing"
                    style={{ transform: `rotate(${rotateDegrees}deg)` }}
                    className={styles['AnnualRing__Svg']}
                />
            </div>
        );
    }
}

export default AnnualRing;
